// COLORS
$primary-color: #3da9fc;
$secondary-color: #ef4565;

$white-color: #F8F9F9;

$dark-grey: #333333;
$light-grey: #d2d2d2;

$dark-blue-text: #272341; // For Headings

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;
